// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    ui_version: 'UI v.1.0.0',

    postLogoutUrl: 'https://delt.qa.dna.int.bayer.com', 

    clientID:'481dcbdc-6bc5-49d3-8570-ac2deeb13ab7',

    redirectUri: 'https://delt.qa.dna.int.bayer.com',

    apiKey:'gM9P6dZmIg5GpRO8R4WIM7sx7bVUHhfT1qlZaR7K',

    authority: 'https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78',

    apiURL: 'https://49w0cbuq69.execute-api.eu-central-1.amazonaws.com/QA',

    dynamoDBURL: 'https://49w0cbuq69.execute-api.eu-central-1.amazonaws.com/QA/dynamodb',

    screenResultUrl: 'https://49w0cbuq69.execute-api.eu-central-1.amazonaws.com/QA/hitsmiles',

    hitlistResultUrl: 'https://49w0cbuq69.execute-api.eu-central-1.amazonaws.com/QA/hitlistDetails',

};
  
  

  