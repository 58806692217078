import { Injectable } from '@angular/core';
import { FILE_EXTENSION } from './enums/File-extension.enum';
import { FILE_TYPE } from './enums/File-type.enum';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { BehaviorSubject } from 'rxjs';

const enum LIBRARY_FILE_TYPE {
  DEFINITION = 'Definition',
  STRUCTURE = 'Structure',
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  //readFileType = '';
  readFileType$ = new  BehaviorSubject<string>('Paired')   
  editProject$ = new BehaviorSubject<boolean>(false)
  adminRole$ = new BehaviorSubject<boolean>(false)

  // 
  showComponentScientist$ = new BehaviorSubject<boolean>(true)
  showComponentAddScientist$ = new BehaviorSubject<boolean>(false)

  validateFileExtension(file: File, extension: FILE_EXTENSION) {
    if (file && file.name.endsWith(extension)) {
      return true;
    }
    return false
  }

  validateFileName(file: File, fileType: FILE_TYPE) {
    if (file && fileType) {
      const nameWithoutExtension = this.removeFileExtension(file.name);
      switch (fileType) {
        case FILE_TYPE.LIBRARY_DEFINITION:
          return this.validateLibraryFileName(nameWithoutExtension);
        case FILE_TYPE.NGS_FILE:
          return this.validateNGSFile(nameWithoutExtension);

        case FILE_TYPE.NGS_EXPERIMENTAL:
          return this.validateNGSExperementalFileName(nameWithoutExtension);
        case FILE_TYPE.LIBRARY_STRUCTURE:
          return this.validateLibraryStructure(nameWithoutExtension);
        default:
          return false
      }
    }

    return false
  }


  // Definition:  LDF_PH_WUXI_DEL-2-2_20230712.xlsx
  // Definition:  LDF_Division_Company_Library Name_20230712.xlsx
  validateLibraryFileName(fileName: string) {
    const splitted = fileName.split('_');
    if (splitted.length != 5) {
      return false
    }

    if (splitted[0] !== 'LDF') {
      return false
    }

    //TODO:SHERIF MAKE THIS ARRAY IN CONSTANTS , should also get that from the database
    //Division
    if (!['PH', 'CS', 'VI'].includes(splitted[1])) {
      return false
    }
    // Company and Library Name 
    if (!this.isAlphanumeric(splitted[2]) || !this.isAlphanumeric(splitted[3])) {
      return false
    }
    // DateTime string format -- YYYYMMDD
    if (!this.isAlphanumeric(splitted[4]) || !this.validateDateTime(splitted[4])) {
      return false
    }

    return true
  }

  // STRUCT_LDF_Division_COMPANY_LIBRARYNAME_YYYYMMDD
  validateLibraryStructure(fileName: string) {
    const splitted = fileName.split('_');
    if (splitted.length != 6) {
      return false
    }

    if (splitted[0] !== 'STRUCT') {
      return false
    }


    if (splitted[1] !== 'LDF') {
      return false
    }

    //TODO:SHERIF MAKE THIS ARRAY IN CONSTANTS , should also get that from the database
    //Division
    if (!['PH', 'CS', 'VI'].includes(splitted[2])) {
      return false
    }

    // Company and Library Name 
    if (!this.isAlphanumeric(splitted[3]) || !this.isAlphanumeric(splitted[4])) {
      return false
    }
    // DateTime string format -- YYYYMMDD
    if (!this.isAlphanumeric(splitted[5]) || !this.validateDateTime(splitted[5])) {
      return false
    }

    return true
  }



  validateDateTime(dateTime: string) {
    const result = /^\d{8}$/.test(dateTime) && !isNaN(Date.parse(`${dateTime.substring(0, 4)}-${dateTime.substring(4, 6)}-${dateTime.substring(6, 8)}`));
    return result;
  }

  // Filename Format: NGS_Division_ProjectName_ScreenName_ExperimentNumber_ReadFileName_ReadNumber
  validateNGSFile(fileName: string) {
    const splitted = fileName.split('_');
    if (splitted[0] == 'Naive') {
      splitted.shift()
    }
    // added second condition to cover single read option
    if (splitted.length != 7 && splitted.length != 6) {
      return false
    }
    if (splitted[0] !== 'NGS') {
      return false
    }
    //TODO:SHERIF MAKE THIS ARRAY IN CONSTANTS , should also get that from the database
    //Division
    
    if (!['PH', 'CS', 'VI'].includes(splitted[1])) {
      return false
    }
    // project name and screen name 
    if (!this.isAlphanumeric(splitted[2]) || !this.isAlphanumeric(splitted[3])) {
      return false
    }
    // // ExperimentNumber  == STRING
    if (!this.isAlphanumeric(splitted[4])) {
      return false
    }
    // read File Name / ReadNumber
    if (!this.isAlphanumeric(splitted[5]) && this.readFileType$.value == 'Paired') {
        return false
    }
    if(splitted[6]) {
      if(splitted[6].includes('.fastq')){
  
         splitted[6] = splitted[6].replaceAll('.fastq','')
      }
       if(['R1', 'R2'].indexOf(splitted[6]) === -1 && this.readFileType$.value == 'Paired'){
         return false
       }
   
    }
    return true
  }

  // Filename Format: EXP_NGS_Division_ProjectName_ScreenName_ExperimentNumber
  validateNGSExperementalFileName(fileName: string) {
    const splitted = fileName.split('_');
    if (splitted.length != 6) {
      return false
    }
    if (splitted[0] !== 'EXP' || splitted[1] !== 'NGS') {
      return false
    }
    //TODO:SHERIF MAKE THIS 8ARRAY IN CONSTANTS , should also get that from the database
    //Division
    if (!['PH', 'CS', 'VI'].includes(splitted[2])) {
      return false
    }
    // project name and screen name
    if (!this.isAlphanumeric(splitted[3]) || !this.isAlphanumeric(splitted[4])) {
      return false
    }
    // // ExperimentNumber
    if (!this.isAlphanumeric(splitted[5])) {
      return false
    }
    return true
  }


  isAlphanumeric(str: string) {
    const result = /^[a-zA-Z0-9]+$/.test(str);
    return result;
  }

  removeFileExtension(filename: string) {
    return filename.replace(/\.[^/.]+$/, "");
  }



  openNotificationDialog(dialog: any, text:string, callBack?: any){
      const dialogRef = dialog.open(NotificationModalComponent, {
        data: { modalText: text },
      });
  
       dialogRef.afterOpened().subscribe((res:any) => {
        setTimeout(() => {
           dialogRef.close();
           if(callBack){
            callBack()
           }
        }, 3000)
      })

      return dialogRef;

  }

}
