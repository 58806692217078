import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, LogLevel, InteractionType } from '@azure/msal-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AgGridModule } from 'ag-grid-angular';

import { MatNativeDateModule } from '@angular/material/core';

import { HomeComponent } from './pages/home/home.component';
import { OldProjectComponent } from './pages/project_old/project.component';
import { ProjectComponent } from './pages/project/project.component';
import { LibraryComponent } from './pages/library/library.component';
import { ScreenComponent } from './pages/screen/screen.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ModalComponentComponent } from './pages/modal-component/modal-component.component';
import { LoginComponent } from './pages/login/login.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { ProcessNewScreenComponent } from './pages/process-new-screen/process-new-screen.component';
import { ProcessNewLibraryComponent } from './pages/process-new-library/process-new-library.component';
import { ModalServiceService } from './modal-service.service';
import { ScientistComponent } from './pages/scientist/scientist.component';
import { HitlistComponent } from './pages/hitlist/hitlist.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HelpcenterComponent } from './pages/helpcenter/helpcenter.component';
import { ModalScreenComponent } from './pages/modal-screen/modal-screen.component';
import { ScientistDetailsComponent } from './pages/scientist-details/scientist-details.component';
import { ConfirmationModal } from './components/confirmation-modal/confirmation-modal';
import { TableComponentComponent } from './components/table-component/table-component.component';
import { SearchTableComponent } from './components/search-table/search-table.component';
import { LibraryModalComponent } from './components/library-modal/library-modal.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ScreenDetailsComponent } from './pages/screen-details/screen-details.component';
import { ScreenResultChartComponent } from './pages/screen-result-chart/screen-result-chart.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from 'src/environments/environment';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { NoAdminComponent } from './pages/no-admin/no-admin.component';
import { HitlistDetailsComponent } from './pages/hitlist-details/hitlist-details.component';
import { LibraryDetailsComponent } from './pages/library-details/library-details.component';
import { FilterPipe } from './components/filter.pipe';
import { SearchPipe } from './components/table-component/search.pipe';
import { ScreenFilter } from './pages/screen/screen-filter.pipe';
import { HitlistFilter } from './pages/hitlist/hitlist-filter.pipe';


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // LOCAL
      clientId: environment.clientID,
      redirectUri: environment.redirectUri, 

      // DEV
      // clientId: environment.clientID_DEV,
      // redirectUri: environment.redirectUri_DEV, 

      // QA
      // clientId: environment.clientID_QA,
      // redirectUri: environment.redirectUri_QA,

      //PROD
      // clientId: environment.clientID_PROD,
      // redirectUri: environment.redirectUri_PROD,

      authority: environment.authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConfirmationModal,
    ProjectComponent,
    ProjectDetailsComponent,
    OldProjectComponent,
    LibraryComponent, 
    ScreenComponent,
    NotFoundComponent, 
    ModalComponentComponent,
    ScientistComponent,
    HitlistComponent, 
    ContactComponent, 
    HelpcenterComponent, 
    ModalScreenComponent,
    LoginComponent, 
    ScientistDetailsComponent, 
    TableComponentComponent, 
    SearchTableComponent, 
    LibraryModalComponent,
    SidenavComponent,
    BarChartComponent,
    ScreenDetailsComponent,
    ScreenResultChartComponent,
    CallbackComponent,
    ProcessNewScreenComponent,
    ProcessNewLibraryComponent,
    NotificationModalComponent,
    LoadingSpinnerComponent,
    NoAdminComponent,
    HitlistDetailsComponent,
    LibraryDetailsComponent,
    FilterPipe,
    SearchPipe,
    ScreenFilter,
    HitlistFilter
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatGridListModule,
    FlexLayoutModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FontAwesomeModule,
    AgGridModule,
    MatSidenavModule,
    MatFormFieldModule, 
    MatSelectModule,
    MatButtonModule,
    HttpClientModule,
    MsalModule,
    NgxSpinnerModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule  
  ],
      // SSO-sherif
  providers: [
    ModalServiceService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:MsalInterceptor,
      multi:true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {}
